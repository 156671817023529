<template>
  <div>
    <div slot="content">
      <v-container fluid>
        <h1>All Shipments</h1>
        <template>
          <v-data-table
            :headers="headers"
            :items="shipments"
            sort-by="shippingDoc.StatusTimestamp"
            :sort-desc="['true']"
            class="elevation-1"
          >
            <template v-slot:top>
              <v-toolbar
                flat
                color="white"
              >
                <v-toolbar-title>All Shipments</v-toolbar-title>
                <v-divider
                  class="mx-4"
                  inset
                  vertical
                ></v-divider>
              </v-toolbar>
            </template>
            <template v-slot:item.shippingDoc.ShipmentDetails.LabelGenerationDate="{ item }">
              {{ $moment(item.shippingDoc.ShipmentDetails.LabelGenerationDate).format('DD-MM-YYYY HH:mm') }}
            </template>
            <template v-slot:item.shippingDoc.StatusTimestamp="{ item }">
              {{ $moment(item.shippingDoc.StatusTimestamp).format('DD-MM-YYYY HH:mm') }}
            </template>
            <template v-slot:item.labelUrl="{ item }">
              <a
                :href="item.labelUrl"
                target="_blank"
              >Label Details</a>
            </template>
            <template v-slot:item.shippingDoc.TrackingUrl="{ item }">
              <a
                :href="item.shippingDoc.TrackingUrl"
                target="_blank"
              >{{ item.trackingNumberID }}</a>
            </template>
            <template v-slot:item.shippingDoc.Status="{ item }">
              {{ item.shippingDoc.Status }}
            </template>
          </v-data-table>
        </template>
      </v-container>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { shippingService } from '@/render/api/zarya'

export default {
  data() {
    return {
      headers: [
        {
          text: 'Patient Name',
          value: 'shippingDoc.ShipmentDetails.Recipient.PersonName',
          width: '30%',
        },
        {
          text: 'Date Shipped',
          value: 'shippingDoc.ShipmentDetails.LabelGenerationDate',
          width: '20%',
        },
        {
          text: 'Status Update',
          value: 'shippingDoc.StatusTimestamp',
          width: '20%',
        },
        { text: 'Label Info', value: 'labelUrl', width: '20%' },
        {
          text: 'Tracking Number',
          value: 'shippingDoc.TrackingUrl',
          width: '20%',
        },
        { text: 'Status', value: 'shippingDoc.Status', width: '20%' },
      ],
      shipments: [],
      orders: [],
    }
  },
  async mounted() {
    await this.getShipmentDetails()
  },
  computed: {
    ...mapGetters(['UserProfile', 'PharmacyProfile']),
  },
  methods: {
    async getShipmentDetails() {
      const token = await this.$auth.getTokenSilently()
      this.shipments = await shippingService
        .getAllShipments(token, this.PharmacyProfile._id)
        .then(response => response)
    },
  },
}
</script>
<style scoped>
h3 {
  /* font-weight: 400; */
  font-size: 16px;
}
.sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 20;
}
#noPadding {
  padding: 0;
}
.fixedElement {
  /* position: fixed; */
  padding: 1% 2%;
  width: 100%;
  z-index: 20;
}
</style>

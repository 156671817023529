var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{attrs:{"slot":"content"},slot:"content"},[_c('v-container',{attrs:{"fluid":""}},[_c('h1',[_vm._v("All Shipments")]),[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.shipments,"sort-by":"shippingDoc.StatusTimestamp","sort-desc":['true']},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-toolbar',{attrs:{"flat":"","color":"white"}},[_c('v-toolbar-title',[_vm._v("All Shipments")]),_c('v-divider',{staticClass:"mx-4",attrs:{"inset":"","vertical":""}})],1)]},proxy:true},{key:"item.shippingDoc.ShipmentDetails.LabelGenerationDate",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.shippingDoc.ShipmentDetails.LabelGenerationDate).format('DD-MM-YYYY HH:mm'))+" ")]}},{key:"item.shippingDoc.StatusTimestamp",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.$moment(item.shippingDoc.StatusTimestamp).format('DD-MM-YYYY HH:mm'))+" ")]}},{key:"item.labelUrl",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.labelUrl,"target":"_blank"}},[_vm._v("Label Details")])]}},{key:"item.shippingDoc.TrackingUrl",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.shippingDoc.TrackingUrl,"target":"_blank"}},[_vm._v(_vm._s(item.trackingNumberID))])]}},{key:"item.shippingDoc.Status",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.shippingDoc.Status)+" ")]}}])})]],2)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }